import {
  Database,
  DateRange,
  NestedCompany,
  NestedDeal,
  NestedPlan,
  NestedUser,
} from "@revelate/types";
import { getMonthsForTimePeriod, getStartEndDates, getStartOfYearToEndOfMonth } from "@revelate/utils";
import { getDealsForDateRange, getDealsValue } from "./deals";
import { checkGoalCondition, getTargetValueFromGoals } from "./goals";
import { format } from "date-fns";
import { CriteriaResult } from "./accelerators";

type QuotaConditionResponse = {
  success: boolean;
  filteredDeals: NestedDeal[];
};

export const checkQuotaCondition = ({
  calculationDate,
  dateRange,
  company,
  user,
  deals,
  plan,
  condition,
  dealsForDateRange,
  filteredDeals,
  includeProjections = false,
  logResult = false,
}: {
  calculationDate: Date;
  dateRange: DateRange;
  company: NestedCompany;
  user: NestedUser;
  deals: NestedDeal[];
  plan: NestedPlan;
  condition: Database["public"]["Tables"]["conditions"]["Row"];
  dealsForDateRange: NestedDeal[];
  filteredDeals: NestedDeal[];
  includeProjections: boolean;
  logResult: boolean;
}): CriteriaResult => {
  let success = false;
  // let filteredDeals = prefilteredDeals;
  const { goals: userGoals } = user;
  // Select the right goal for the month
  // TODO: Attach the quotas to the user, not the goals
  const quota = userGoals?.find(
    (goal) => goal.quota_id === condition.quota_id
  )?.quota;
  if (!quota || !plan.time_period)
    return {
      success,
      successQuotaTargetValue: null,
      filteredDeals,
    };
  // Map all ids of deals that exist in deals but not in filteredDeals since these were removed by previous conditions
  const removedDealIds = dealsForDateRange
    .map((deal) => deal.id)
    .filter((id) => !filteredDeals.map((deal) => deal.id).includes(id));

  // Get all deals for the current period
  const quotaDeals = getDealsForDateRange({
    deals,
    dateRange: getStartEndDates(dateRange, calculationDate, plan.time_period),
    dealTypes: quota.deal_types,
    providers: quota.providers || undefined,
    dealRole: plan.deal_role,
    userId: user.id,
  })
    .filter((deal) => !removedDealIds.includes(deal.id))
    .filter((deal) => (includeProjections ? true : deal.is_won));

  // Get all deals for the YTD period
  const ytdDateRange = getStartOfYearToEndOfMonth(calculationDate);
  const quotaDealsYTD = getDealsForDateRange({
    deals,
    dateRange: ytdDateRange,
    dealTypes: quota.deal_types,
    providers: quota.providers || undefined,
    dealRole: plan.deal_role,
    userId: user.id,
  })
    .filter((deal) => !removedDealIds.includes(deal.id))
    .filter((deal) => (includeProjections ? true : deal.is_won));

  const { calculation_period } = quota;
  const isCurrentPeriod = calculation_period === "current_period";

  // Get target value for the current period or YTD
  const goals = userGoals?.filter(
    (goal) => goal.quota_id === condition.quota_id
  );

  const targetValue = getTargetValueFromGoals(
    calculationDate,
    company,
    plan,
    goals,
    isCurrentPeriod
  ) * getMonthsForTimePeriod(plan.time_period);

  if (targetValue === 0) {
    console.error("Error: Missing target value for quota with id:", quota.id);
    return {
      success: false,
      successQuotaTargetValue: null,
      filteredDeals,
    };
  }

  // Replace filtered deals with ytdDeals if calculation period is YTD
  // filteredDeals = isCurrentPeriod ? filteredDeals : ytdDeals;

  if (
    quota?.target_metric === "target_deal_value" ||
    quota?.target_metric === "target_onboarding_value"
  ) {
    // Check if user attained the target deal value for the period or YTD
    const isOnboardingValue =
      quota?.target_metric === "target_onboarding_value";
    const actualValue = isCurrentPeriod
      ? getDealsValue(company, quotaDeals, isOnboardingValue)
      : getDealsValue(company, quotaDealsYTD, isOnboardingValue);

    success = checkGoalCondition(actualValue, targetValue, condition);

    if (logResult)
      console.log(
        `-- ${Number(format(calculationDate, "yyyy"))}-${Number(
          format(calculationDate, "MM")
        )} --`,
        `\n${plan.name} (ID: ${plan.id})`,
        `\n${user.email}`,
        `\n\nQuota:`,
        quota.id,
        `\nActual value:`,
        actualValue,
        `\nTarget value:`,
        targetValue,
        `\nCondition:`,
        condition,
        `\nSuccess:`,
        success
      );
  }

  if (quota?.target_metric === "target_number_of_deals") {
    // Check if user attained the target deal count for the period or YTD
    const actualValue = isCurrentPeriod
      ? quotaDeals.length
      : quotaDealsYTD.length;
    success = checkGoalCondition(actualValue, targetValue, condition);

    if (logResult)
      console.log(
        `-- ${Number(format(calculationDate, "yyyy"))}-${Number(
          format(calculationDate, "MM")
        )} --`,
        `\n${plan.name} (ID: ${plan.id})`,
        `\n${user.email}`,
        `\n\nQuota:`,
        quota.id,
        `\nActual value:`,
        actualValue,
        `\nTarget value:`,
        targetValue,
        `\nCondition:`,
        condition,
        `\nSuccess:`,
        success
      );
  }

  return {
    success,
    successQuotaTargetValue: success ? targetValue : null,
    filteredDeals,
  };
};
