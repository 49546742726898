import {
  ChevronLeft,
  ChevronRight,
  Check,
  X,
  PiggyBank,
  Trash2Icon,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from "@/components/ui/pagination";
import { Separator } from "@/components/ui/separator";
import { useAppStore } from "@/stores";
import { format, parse } from "date-fns";
import { getCommissionsValue, getPercentage } from "@revelate/calc";
import {
  getBadgeVariant,
  getCurrencyFormatted,
  isApproved,
  isUnapproved,
} from "@/lib/app";
import {
  CommissionStatus,
  CommissionUser,
  NestedCommission,
} from "@revelate/types";
import { abbreviateLongString, capitalizeFirstLetter } from "@revelate/utils";
import { useLocation } from "wouter";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import {
  getBadgeTitle,
  groupCommissionsByDealName,
  groupCommissionsByMonth,
} from "@/lib/app";
import { getRoute } from "@/lib/routes";
import { ConfirmDeletionDialog } from "@/components/ConfirmDeletionDialog";

interface ReportCardProps {
  timePeriod: string;
  employees: CommissionUser[];
  ids: string[];
  commissions: NestedCommission[];
  updateCommissions: (
    previousStatus: CommissionStatus,
    newStatus: CommissionStatus,
    filterByCommission?: NestedCommission
  ) => void;
  isUpdatingCommissions?: boolean;
}

export const ReportCard = ({
  timePeriod,
  employees,
  ids,
  commissions,
  updateCommissions,
  isUpdatingCommissions = false,
}: ReportCardProps) => {
  const currentUser = useAppStore((state) => state.currentUser);
  const currentEmployeeIndex = employees.findIndex((employee) =>
    ids.includes(employee.id)
  );
  const [, setLocation] = useLocation();

  const groupedCommissions = groupCommissionsByMonth(commissions);

  const showPreviousEmployee = () => {
    const previousEmployee =
      currentEmployeeIndex === 0
        ? employees[employees.length - 1]
        : employees[currentEmployeeIndex - 1];
    if (previousEmployee)
      setLocation(getRoute("reports", "user", previousEmployee.id));
  };

  const getClosedAt = (commissions: NestedCommission[]) => {
    const dates: string[] = [];
    // Filter out non-unique deal_ids
    commissions.forEach((commission) => {
      if (commission?.deal?.closed_at) {
        // Check if dates contains the exact string
        const formattedDate = format(commission?.deal?.closed_at, "yyyy-MM-dd");
        if (!dates.includes(formattedDate)) dates.push(formattedDate);
      }
    });
    return dates.length > 0
      ? `Closed ${dates.join(" and ")}`
      : "Based on all deals for the period";
  };

  const getProviderId = (commissions: NestedCommission[]) => {
    const providerIds: string[] = [];
    for (const commission of commissions) {
      const providerId = commission?.deal?.provider_id;
      if (providerId) {
        if (!providerIds.includes(providerId)) providerIds.push(providerId);
      }
    }
    const providerIdsText =
      providerIds && providerIds.length > 2
        ? providerIds.slice(0, 2).join(" and ") +
          ` and ${providerIds.length - 2} more`
        : providerIds.join(" and ");
    return commissions[0]?.deal && providerIds.length > 0
      ? `${capitalizeFirstLetter(
          commissions[0]?.deal?.provider
        )} ID ${providerIdsText}`
      : commissions[0]?.deal
        ? "No provider ID"
        : "";
  };

  const showNextEmployee = () => {
    const nextEmployee =
      currentEmployeeIndex === employees.length - 1
        ? employees[0]
        : employees[currentEmployeeIndex + 1];
    if (nextEmployee) setLocation(getRoute("reports", "user", nextEmployee.id));
  };

  return (
    <div>
      <Tabs defaultValue="detailed">
        <Card className="overflow-hidden" x-chunk="dashboard-05-chunk-4">
          <CardHeader className="flex flex-column items-start bg-muted/50 gap-3">
            {/* <div className="grid gap-0.5">
              <Scope />
            </div> */}

            <TabsList>
              <TabsTrigger value="detailed">Details</TabsTrigger>
              <TabsTrigger value="summary">Summary</TabsTrigger>
            </TabsList>
          </CardHeader>
          <CardContent className="p-6 text-sm">
            <div className="grid gap-3">
              {Object.entries(groupedCommissions).map(([key, value]) => (
                <div key={key}>
                  <div className="mb-4 align-middle">
                    <span className="font-semibold mr-2 align-middle">
                      {format(parse(key, "yyyy-MM", new Date()), "MMMM yyyy")}
                    </span>
                    <Badge
                      className="font-medium"
                      variant={
                        getBadgeVariant(value) === "paid"
                          ? "secondary"
                          : "default"
                      }
                    >
                      {getBadgeTitle(value)}
                    </Badge>
                  </div>
                  <ul className="grid gap-4">
                    {Object.entries(groupCommissionsByDealName(value)).map(
                      ([dealName, commissions]) => {
                        return (
                          <li key={dealName}>
                            <ul className="grid gap-1">
                              <li className="flex flex-col  justify-between">
                                <div>
                                  <span className="text-muted-foreground">
                                    <div className="flex flex-row my-1">
                                      <div className="flex items-center gap-2">
                                        {/* <DropdownMenu>
                                          <DropdownMenuTrigger asChild>
                                            <Button
                                              size="icon"
                                              variant="outline"
                                              className="h-10 w-10"
                                            >
                                              <Check className="h-5 w-5" />
                                              <span className="sr-only">
                                                More
                                              </span>
                                            </Button>
                                          </DropdownMenuTrigger>
                                          <DropdownMenuContent align="end">
                                            <DropdownMenuItem>
                                              Approve
                                            </DropdownMenuItem>
                                            <DropdownMenuSeparator />
                                            <DropdownMenuItem disabled>
                                              Reject
                                            </DropdownMenuItem>
                                            <DropdownMenuItem disabled>
                                              Delete
                                            </DropdownMenuItem>
                                          </DropdownMenuContent>
                                        </DropdownMenu> */}

                                        <div className="flex flex-col">
                                          <strong className="mr-2 align-middle">
                                            {abbreviateLongString(dealName, 30)}
                                          </strong>
                                          <span className="text-muted-foreground text-xs">
                                            {getClosedAt(commissions)}
                                          </span>
                                          <span className="text-muted-foreground text-xs">
                                            {getProviderId(commissions)}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="ml-auto flex items-center font-semibold gap-2">
                                        <span>
                                          {getCurrencyFormatted(
                                            getCommissionsValue(commissions)
                                          )}
                                        </span>
                                        <span>
                                          {currentUser?.role ===
                                            "super_admin" ||
                                          currentUser?.role === "admin" ? (
                                            isUnapproved(commissions) ? (
                                              <Button
                                                onClick={() =>
                                                  updateCommissions(
                                                    "unapproved",
                                                    "approved",
                                                    commissions[0]
                                                  )
                                                }
                                                size="icon"
                                                variant="default"
                                                className="bg-[#6B6397]"
                                                loading={isUpdatingCommissions}
                                              >
                                                <Check className="h-5 w-5" />
                                              </Button>
                                            ) : isApproved(commissions) ? (
                                              <Button
                                                onClick={() =>
                                                  updateCommissions(
                                                    "approved",
                                                    "paid",
                                                    commissions[0]
                                                  )
                                                }
                                                size="icon"
                                                variant="default"
                                                className="bg-[#6B6397]"
                                                loading={isUpdatingCommissions}
                                              >
                                                <PiggyBank className="h-5 w-5" />
                                              </Button>
                                            ) : getCommissionsValue(
                                                commissions
                                              ) > 0 ? (
                                              <ConfirmDeletionDialog
                                                key={`delete-${dealName}`}
                                                title={`Are you sure you want to revert commissions for ${dealName} for ${format(
                                                  parse(
                                                    key,
                                                    "yyyy-MM",
                                                    new Date()
                                                  ),
                                                  "MMMM yyyy"
                                                )}?`}
                                                description="All commissions related to this deal will be set to the default state."
                                                deleteId={dealName}
                                                deleteOperation={async () => {
                                                  updateCommissions(
                                                    "paid",
                                                    "unapproved",
                                                    commissions[0]
                                                  );
                                                }}
                                              >
                                                <Button
                                                  size="icon"
                                                  variant="outline"
                                                  loading={
                                                    isUpdatingCommissions
                                                  }
                                                >
                                                  <Trash2Icon className="h-5 w-5" />
                                                </Button>
                                              </ConfirmDeletionDialog>
                                            ) : (
                                              <></>
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                                <TabsContent value="detailed">
                                  {commissions?.map(
                                    (commission, commissionIndex) => (
                                      <div
                                        className="flex flex-row my-2 text-xs"
                                        key={commissionIndex}
                                      >
                                        <div className="flex flex-col">
                                          <div className="">
                                            <strong className="text-muted-foreground mr-2 my-1">
                                              {getPercentage(commission.rate)}{" "}
                                              of{" "}
                                              {getCurrencyFormatted(
                                                commission.commission_basis
                                              )}{" "}
                                            </strong>{" "}
                                            <Badge variant={commission.status}>
                                              {capitalizeFirstLetter(
                                                commission.status
                                              )}
                                            </Badge>
                                          </div>
                                          <span className="text-muted-foreground">
                                            {commission.accelerator
                                              ? `${commission.plan.name} - ${commission.accelerator.name}` //  (ID: ${commission.accelerator.id})
                                              : `Accelerator ${
                                                  commissionIndex + 1
                                                }`}{" "}
                                          </span>
                                        </div>
                                        <div className="ml-auto flex items-top gap-2">
                                          <div className="text-muted-foreground">
                                            {getCurrencyFormatted(
                                              commission.amount
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </TabsContent>
                              </li>
                            </ul>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <Separator className="my-4" />
                </div>
              ))}

              <ul className="grid gap-3">
                <li className="flex items-center justify-between font-semibold">
                  <div>
                    <span className="text-muted-foreground">
                      Total for period
                    </span>
                  </div>
                  <div className="ml-auto flex items-center gap-2">
                    <div>
                      <span>
                        {getCurrencyFormatted(getCommissionsValue(commissions))}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </CardContent>
          <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
            <div className="text-xs text-muted-foreground">
              <time dateTime={timePeriod}>{timePeriod}</time>
            </div>
            <Pagination className="ml-auto mr-0 w-auto">
              <PaginationContent>
                <PaginationItem>
                  <Button
                    onClick={() => showPreviousEmployee()}
                    size="icon"
                    variant="outline"
                    className="h-6 w-6"
                  >
                    <ChevronLeft className="h-3.5 w-3.5" />
                    <span className="sr-only">Previous Employee</span>
                  </Button>
                </PaginationItem>
                <PaginationItem>
                  <Button
                    onClick={() => showNextEmployee()}
                    size="icon"
                    variant="outline"
                    className="h-6 w-6"
                  >
                    <ChevronRight className="h-3.5 w-3.5" />
                    <span className="sr-only">Next Employee</span>
                  </Button>
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardFooter>
        </Card>
      </Tabs>
    </div>
  );
};
