import {
  AcceleratorWithConditions,
  DateRange,
  NestedCompany,
  NestedDeal,
  NestedPlan,
  NestedUser,
} from "@revelate/types";
import { conditionMet } from "./conditions";

export type CriteriaResult = {
  success: boolean;
  successQuotaTargetValue: number | null;
  filteredDeals: NestedDeal[];
};

export const criteriaMet = ({
  calculationDate,
  dateRange,
  company,
  user,
  deals,
  plan,
  accelerator,
  dealsForDateRange,
  includeProjections = false,
  logResult = false,
}: {
  calculationDate: Date;
  dateRange: DateRange;
  company: NestedCompany;
  user: NestedUser;
  deals: NestedDeal[];
  plan: NestedPlan;
  accelerator: AcceleratorWithConditions;
  dealsForDateRange: NestedDeal[];
  includeProjections: boolean;
  logResult: boolean;
}): CriteriaResult => {
  const { criteria, conditions } = accelerator;
  if (criteria === "always")
    return {
      success: true,
      successQuotaTargetValue: null,
      filteredDeals: dealsForDateRange,
    };
  // Keep filtering the deals for every condition
  let filteredDeals = dealsForDateRange;
  const successes: boolean[] = [];
  const successQuotaTargetValues: number[] = [];
  for (const condition of conditions) {
    const {
      filteredDeals: filteredDealsFromCondition,
      successQuotaTargetValue: successQuotaTargetValue,
      success: successFromCondition,
    } = conditionMet({
      calculationDate,
      dateRange,
      company,
      user,
      deals,
      plan,
      condition,
      dealsForDateRange: filteredDeals,
      includeProjections,
      logResult,
    });
    filteredDeals = filteredDealsFromCondition;
    successes.push(successFromCondition);
    if (successQuotaTargetValue)
      successQuotaTargetValues.push(successQuotaTargetValue);
  }
  const success =
    criteria === "all_criteria_met"
      ? successes.every((s) => s)
      : criteria === "any_criteria_met"
        ? successes.some((s) => s)
        : false;

  return {
    success,
    successQuotaTargetValue:
      successQuotaTargetValues && successQuotaTargetValues.length > 0
        ? successQuotaTargetValues[0]
        : null,
    filteredDeals,
  };
};
